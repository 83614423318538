import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import accountReducer from 'src/redux/reducers/accountReducer';
import notificationsReducer from 'src/redux/reducers/notificationsReducer';
import instancesReducer from 'src/features/instances/instancesSlice';
import usersReducer from 'src/features/users/usersSlice';
import settingsReducer from 'src/features/settings/settingsSlice';
import mathTableReducer from 'src/features/mathTable/mathTableSlice';

import dataAutomationReducer from 'src/features/dataAutomation/dataAutomationSlice';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  form: formReducer,
  instances: instancesReducer,
  users: usersReducer,
  settings: settingsReducer,
  mathTable: mathTableReducer,
  dataAutomation: dataAutomationReducer
});

export default rootReducer;
