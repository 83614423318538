import React from 'react';

import CookiesNotification from 'src/components/CookiesNotification';
import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import { AppProviders } from 'src/context';

const App = () => (
  <AppProviders>
    <ScrollReset />
    <GoogleAnalytics />
    <CookiesNotification />
    <SettingsNotification />
  </AppProviders>
);

export default App;
