import React from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
//import { useAsync } from 'src/utils';
import * as Sentry from '@sentry/react';
import CONSTS from 'src/constants';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, `${window.location.pathname}/auth`);

const Auth0Context = React.createContext();

const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState();
  const [user, setUser] = React.useState();
  const [auth0Client, setAuth0Client] = React.useState();
  const [isLoading, setLoading] = React.useState(true);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  //const { data, status, run } = useAsync();

  React.useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...initOptions,
        advancedOptions: {
          defaultScope: 'openid profile email app_metadata user_metadata'
        }
      });
      setAuth0Client(auth0FromHook);

      // Get error message
      if (window.location.search.includes('error=')) {
        const params = new URL(document.location).searchParams;
        //const searchParams = new URLSearchParams(paramsString);
        const error = params.get('error');
        const errorDescription = params.get('error_description');

        setError({ error, errorDescription });
      }
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        //console.log('user', user);
        if (CONSTS.isProd) {
          Sentry.setUser({
            email: user.email,
            username: user.name ?? user.given_name + user.family_name
          });
        }
        setUser(user);

        //const accessToken = await auth0FromHook.getTokenSilently();

        //console.log('tokenIdClaims', accessToken);
        //window.localStorage.setItem('__rfAuthToken', accessToken);
      }

      setLoading(false);
    };
    //run(initAuth0());
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        popupOpen,
        error,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

function useAuth0() {
  const context = React.useContext(Auth0Context);
  if (context === undefined) {
    throw new Error(`useAuth0 must be used within a Auth0Provider`);
  }
  return context;
}

export { Auth0Provider, useAuth0 };
