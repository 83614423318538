//import { queryCache } from 'react-query';
import CONSTS from 'src/constants';
import * as Sentry from '@sentry/react';
//const localStorageKey = '__rfAuthToken';
async function client(endpoint, { body, ...customConfig } = {}) {
  // Ignore this... It's the *only* thing we need to do thanks to the way we
  // handle fetch requests with the service worker. In your apps you shouldn't
  // need to have something like this.
  //await window.__bookshelf_serverReady
  // generate unique transactionId and set as Sentry tag
  const transactionId = Math.random().toString(36).substr(2, 9);

  if (CONSTS.isProd) {
    Sentry.configureScope(function (scope) {
      scope.setTag('transaction_id', transactionId);
    });
  }

  //const token = window.localStorage.getItem(localStorageKey);
  const headers = { 'content-type': 'application/json', 'X-Transaction-ID': transactionId };
  //if (token) {
  //  headers.Authorization = `Bearer ${token}`;
  //}
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  return window.fetch(`/api/v1/${endpoint}`, config).then(async (r) => {
    if (r.status === 401) {
      //logout();
      // refresh the page for them
      //window.location.assign(window.location);
      return Promise.reject({ message: 'Please re-authenticate.' });
    }
    const data = await r.json();
    if (r.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

export { client };
