import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { fetchInstances } from 'src/features/instances/instancesSlice';
import { AuthorizeAnalytics } from 'src/components/googleanalytics';

import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ children, ...rest }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { loadGaData } = useSelector((state) => state.settings.ga);
  const { instancesError } = useSelector((state) => state.instances);

  React.useEffect(() => {
    dispatch(fetchInstances());
  }, [dispatch]);

  if (instancesError) {
    return (
      <div>
        <h1>Something went wrong Getting Instances...</h1>
        <div>{instancesError.toString()}</div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {loadGaData && <AuthorizeAnalytics />}

      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
