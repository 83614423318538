import { createSlice } from '@reduxjs/toolkit';
// import { getSettingById } from 'src/api';
import CONSTS from 'src/constants';

const settingsInitialState = {
  ga: {
    viewId: 'ga:186585986',
    instanceNameCustomDimensionNum: 2,
    userNameCustomDimensionNum: 4,
    loadGaData: CONSTS.isDev ? false : true
  },
  hasFetchedData: false,
  isLoading: false,
  error: null
};

function startLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

/*
function setCurrentSetting(state, action) {
  state.currentSetting = action.payload;
}

*/

const settings = createSlice({
  name: 'settings',
  initialState: settingsInitialState,
  reducers: {
    getSettingStart: startLoading,
    getSettingSuccess(state, { payload }) {
      state.currentSetting = payload;
      state.currentSetting.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    setLoadGaData(state, { payload }) {
      state.ga.loadGaData = payload;
    },
    getSettingFailure: loadingFailed
  }
});

export const {
  getSettingStart,
  getSettingSuccess,
  getSettingFailure,
  setLoadGaData
} = settings.actions;

export default settings.reducer;
/*
export const fetchSettings = () => async (dispatch) => {
  try {
    dispatch(getSettingsStart());
    const settings = await getSettings();
    dispatch(getSettingsSuccess(settings));
  } catch (err) {
    dispatch(getSettingsFailure(err.toString()));
  }
};
*/
/*
export const fetchSettingById = (settingId) => async (dispatch) => {
  try {
    dispatch(getSettingStart());
    const setting = await getSettingById(settingId);
    // console.log('setting from fetch setting by id', setting);
    dispatch(getSettingSuccess(setting));
  } catch (err) {
    dispatch(getSettingFailure(err.toString()));
  }
};
*/
