import React from 'react';

// =====================================================================
// Keep track of components in viewport
// =====================================================================

export const useIsInView = (margin = '0px') => {
  const [isIntersecting, setIntersecting] = React.useState(false);
  const ref = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin: margin }
    );
    if (ref.current) observer.observe(ref.current);
    let currentRef = ref.current;
    return () => {
      observer.unobserve(currentRef);
    };
  }, [margin]);

  return [ref, isIntersecting];
};

// =====================================================================
// Keep track of useEffect updates
// =====================================================================

export const usePrevious = value => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useIsMountedRef = () => {
  const isMounted = React.useRef(true);

  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
};
