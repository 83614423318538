// import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useReportStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 32,
      paddingRight: 32
    }
  }
}));
