import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/utils/chartjs';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'src/mock';

import { registerObserver } from 'react-perf-devtool';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';

import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider, restoreSettings } from 'src/context/settings-context';
import store from 'src/app/store';
import ErrorBoundry from 'src/components/modals/ErrorBoundry';

import CONSTS from './constants';

// Error Reporting
if (CONSTS.isProd) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${process.env.REACT_APP_CLIENT_NAME}@${process.env.REACT_APP_RELEASE_VERSION}`,
    environment: process.env.NODE_ENV
  });
}

if (CONSTS.isDev) registerObserver();

const settings = restoreSettings();

const render = () => {
  const App = require('./app/App').default;

  ReactDOM.render(
    <ErrorBoundry>
      <Provider store={store}>
        <SettingsProvider settings={settings}>
          <App />
        </SettingsProvider>
      </Provider>
    </ErrorBoundry>,
    document.getElementById('root')
  );
};

// Render the app
render();

// HMR for redux

if (CONSTS.isDev && module.hot) {
  module.hot.accept('./app/App', render);
}

serviceWorker.unregister();
