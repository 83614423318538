import { createSlice } from '@reduxjs/toolkit';
import { getUserById, getUsers } from 'src/api';

const usersInitialState = {
  usersById: {},
  currentPageUsers: [],
  currentUser: { hasFetchedData: false },
  pageCount: 0,
  pageLinks: {},
  hasFetchedData: false,
  isLoading: false,
  error: null
};
/*
function startLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}
*/
function startUserLoading(state) {
  state.currentUser.hasFetchedData = false;
  state.currentUser.isLoading = true;
}

function loadingUserFailed(state, action) {
  state.currentUser.isLoading = false;
  state.currentUser.error = action.payload;
}

function startUsersLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingUsersFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

/*
function setCurrentUser(state, action) {
  state.currentUser = action.payload;
}*/

const users = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    getUserStart: startUserLoading,
    getUsersStart: startUsersLoading,
    getUserSuccess(state, { payload }) {
      state.currentUser = payload;

      state.currentUser.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    getUsersSuccess(state, { payload }) {
      state.users = payload;

      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    getUserFailure: loadingUserFailed,
    getUsersFailure: loadingUsersFailed
  }
});

export const {
  getUserStart,
  getUserSuccess,
  getUserFailure,
  getUsersStart,
  getUsersSuccess,
  getUsersFailure
} = users.actions;

export default users.reducer;

export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(getUsersStart());
    const users = await getUsers();
    dispatch(getUsersSuccess(users));
  } catch (err) {
    dispatch(getUsersFailure(err.toString()));
  }
};

export const fetchUserById = (userId) => async (dispatch) => {
  try {
    dispatch(getUserStart());
    const user = await getUserById(userId);
    // console.log('user from fetch user by id', user);
    dispatch(getUserSuccess(user));
  } catch (err) {
    dispatch(getUserFailure(err.toString()));
  }
};
