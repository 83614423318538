import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/components/layouts/DashboardLayout';
// import DocsLayout from 'src/components/layouts/DocsLayout';
import MainLayout from 'src/components/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthRoute from 'src/components/AuthRoute';

const InstancesView = lazy(() => import('src/views/reports/InstancesView'));
const InstanceView = lazy(() => import('src/views/reports/InstanceView'));
const AllUsersView = lazy(() => import('src/views/reports/AllUsersView'));
const UserView = lazy(() => import('src/views/reports/UserView'));
const FilesView = lazy(() => import('src/views/data-automation'));
const SchemasView = lazy(() => import('src/views/data-automation/Schemas'));

function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to="/auth" />
        <Redirect exact from="/login" to="/auth" />
        <Route exact path="/auth" component={lazy(() => import('src/views/auth/LoginView'))} />

        <Route exact path="/404" component={lazy(() => import('src/views/pages/Error404View'))} />

        <AuthRoute path="/app">
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Switch>
                <Redirect exact from="/app" to="/app/reports/instances" />
                <Route
                  exact
                  path="/app/account"
                  component={lazy(() => import('src/views/pages/AccountView'))}
                />
                <Route
                  exact
                  path="/app/reports/dashboard-example"
                  component={lazy(() => import('src/views/reports/DashboardView'))}
                />
                <Route
                  exact
                  path="/app/reports/dashboard-alternative"
                  component={lazy(() => import('src/views/reports/DashboardAlternativeView'))}
                />
                <Route exact path="/app/reports/all/users">
                  <AllUsersView />
                </Route>
                <Route exact path="/app/reports/instances">
                  <InstancesView />
                </Route>

                <Route exact path="/app/reports/instances/:instanceName">
                  <InstanceView />
                </Route>

                <Route exact path="/app/reports/instances/:instanceName/users/:userId">
                  <UserView />
                </Route>
                {/* DATA AUTOMATION */}
                <Route exact path="/app/data/automation">
                  <FilesView />
                </Route>
                <Route exact path="/app/data/schemas">
                  <SchemasView />
                </Route>
                {/* MANAGEMENT */}
                <Route
                  exact
                  path="/app/management/customers"
                  component={lazy(() => import('src/views/management/CustomerListView'))}
                />
                <Route
                  exact
                  path="/app/management/customers/:id"
                  component={lazy(() => import('src/views/management/CustomerDetailsView'))}
                />
                <Route
                  exact
                  path="/app/management/customers/:id/edit"
                  component={lazy(() => import('src/views/management/CustomerEditView'))}
                />
                <Route
                  exact
                  path="/app/management/products"
                  component={lazy(() => import('src/views/management/ProductListView'))}
                />
                <Route
                  exact
                  path="/app/management/products/create"
                  component={lazy(() => import('src/views/management/ProductCreateView'))}
                />
                <Route
                  exact
                  path="/app/management/orders"
                  component={lazy(() => import('src/views/management/OrderListView'))}
                />
                <Route
                  exact
                  path="/app/management/orders/:id"
                  component={lazy(() => import('src/views/management/OrderDetailsView'))}
                />
                <Route
                  exact
                  path="/app/management/invoices"
                  component={lazy(() => import('src/views/management/InvoiceListView'))}
                />
                <Route
                  exact
                  path="/app/management/invoices/:id"
                  component={lazy(() => import('src/views/management/InvoiceDetailsView'))}
                />
                <Route
                  exact
                  path="/app/projects/overview"
                  component={lazy(() => import('src/views/projects/OverviewView'))}
                />
                <Route
                  exact
                  path="/app/projects/browse"
                  component={lazy(() => import('src/views/projects/ProjectBrowseView'))}
                />
                <Route
                  exact
                  path="/app/projects/create"
                  component={lazy(() => import('src/views/projects/ProjectCreateView'))}
                />
                <Route
                  exact
                  path="/app/projects/:id"
                  component={lazy(() => import('src/views/projects/ProjectDetailsView'))}
                />
                <Route
                  exact
                  path="/app/social/feed"
                  component={lazy(() => import('src/views/social/FeedView'))}
                />
                <Route
                  exact
                  path="/app/social/profile"
                  component={lazy(() => import('src/views/social/ProfileView'))}
                />
                {/*
                <Route
                  exact
                  path="/app/extra/charts/apex"
                  component={lazy(() => import('src/views/extra/charts/ApexChartsView'))}
                />
                <Route
                  exact
                  path="/app/extra/forms/formik"
                  component={lazy(() => import('src/views/extra/forms/FormikView'))}
                />
                <Route
                  exact
                  path="/app/extra/forms/redux"
                  component={lazy(() => import('src/views/extra/forms/ReduxFormView'))}
                />
                <Route
                  exact
                  path="/app/extra/editors/draft-js"
                  component={lazy(() => import('src/views/extra/editors/DraftEditorView'))}
                />
                <Route
                  exact
                  path="/app/extra/editors/quill"
                  component={lazy(() => import('src/views/extra/editors/QuillEditorView'))}
                />*/}
                <Route
                  exact
                  path="/app/external-api"
                  component={lazy(() => import('src/views/pages/ExternalApiView'))}
                />
                <Redirect to="/404" />
              </Switch>
            </Suspense>
          </DashboardLayout>
        </AuthRoute>
        {/*<Route
          path="/docs"
          render={props => (
            <DocsLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Redirect exact from="/docs" to="/docs/welcome" />
                  <Route
                    exact
                    path="/docs/welcome"
                    component={lazy(() => import('src/views/docs/WelcomeView'))}
                  />
                  <Route
                    exact
                    path="/docs/getting-started"
                    component={lazy(() => import('src/views/docs/GettingStartedView'))}
                  />
                  <Route
                    exact
                    path="/docs/environment-variables"
                    component={lazy(() => import('src/views/docs/EnvironmentVariablesView'))}
                  />
                  <Route
                    exact
                    path="/docs/deployment"
                    component={lazy(() => import('src/views/docs/DeploymentView'))}
                  />
                  <Route
                    exact
                    path="/docs/api-calls"
                    component={lazy(() => import('src/views/docs/ApiCallsView'))}
                  />
                  <Route
                    exact
                    path="/docs/analytics"
                    component={lazy(() => import('src/views/docs/AnalyticsView'))}
                  />
                  <Route
                    exact
                    path="/docs/authentication"
                    component={lazy(() => import('src/views/docs/AuthenticationView'))}
                  />
                  <Route
                    exact
                    path="/docs/routing"
                    component={lazy(() => import('src/views/docs/RoutingView'))}
                  />
                  <Route
                    exact
                    path="/docs/settings"
                    component={lazy(() => import('src/views/docs/SettingsView'))}
                  />
                  <Route
                    exact
                    path="/docs/state-management"
                    component={lazy(() => import('src/views/docs/StateManagementView'))}
                  />
                  <Route
                    exact
                    path="/docs/theming"
                    component={lazy(() => import('src/views/docs/ThemingView'))}
                  />
                  <Route
                    exact
                    path="/docs/support"
                    component={lazy(() => import('src/views/docs/SupportView'))}
                  />
                  <Route
                    exact
                    path="/docs/changelog"
                    component={lazy(() => import('src/views/docs/ChangelogView'))}
                  />
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            </DocsLayout>
        )} />*/}
        <Route
          path="*"
          render={(props) => (
            <MainLayout {...props}>
              <Switch>
                <Route exact path="/home" component={HomeView} />
                <Route
                  exact
                  path="/pricing"
                  component={lazy(() => import('src/views/pages/PricingView'))}
                />
                <Redirect to="/404" />
              </Switch>
            </MainLayout>
          )}
        />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

export default Routes;
