import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
        textRendering: 'optimizeSpeed'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      '.MuiTableCell-root': {
        padding: '14px !important',
        fontFamily: 'Roboto, Helvetica, Arial, "sans-serif" !important',
        fontSize: '14px !important',
        '&.MuiTableCell-head': {
          fontWeight: '400 !important',
          fontSize: '15px !important'
        },
        '&.MuiTableCell-body': {
          fontWeight: '400 !important',
          fontSize: '14px !important'
        }
      }
    }
  })
);

export const GlobalStyles = () => {
  useStyles();

  return null;
};
