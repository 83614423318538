import React from 'react';
import clsx from 'clsx';
import { Card, CardHeader, Divider, makeStyles, Typography } from '@material-ui/core';

import { useReportStyles } from 'src/styles';
import GenericMoreButton from 'src/components/GenericMoreButton';

const useChartStyles = makeStyles(() => ({
  root: { paddingTop: '0 !important', paddingBottom: '0 !important' },
  chart: {
    height: '100%'
  }
}));

const MainCard = ({ className, title, subtitle, children }) => {
  const classes = useReportStyles();
  const chartClasses = useChartStyles();

  return (
    <Card className={clsx(classes, chartClasses.root, className)}>
      <CardHeader
        action={<GenericMoreButton />}
        title={
          <>
            <Typography color="textPrimary" style={{ display: 'inline-flex' }}>
              {title ?? ''}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ display: 'inline-flex', marginLeft: 5 }}
            >
              {subtitle ? `(${subtitle})` : ''}
            </Typography>
          </>
        }
      />
      <Divider />
      {children}
    </Card>
  );
};

MainCard.defaultProps = {
  isChart: false,
  title: '',
  subtitle: ''
};

export default MainCard;
