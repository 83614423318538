import { createSelector } from '@reduxjs/toolkit';
import { emptyUserActivityObj, sumCommonUserActivity } from 'src/components/user-activity/helpers';
import { salesCommsTotals, sumMonthlyTotal, avgUserActivity, sumDayByDateRange } from './functions';

const instancesByIdSelector = (state) => state.instances.instancesById;
export const currentInstanceSelector = (state) => state.instances.currentInstance;
export const instancesTotalsSelector = (state) => state.instances.totals;

export const instancesAsArraySelector = createSelector(instancesByIdSelector, (instancesById) =>
  Object.entries(instancesById).map(([ids, instances]) => instances)
);

export const instancesCommSalesTotalsSelector = createSelector(
  instancesAsArraySelector,
  (instances) => {
    return instances.reduce((acc, instance) => {
      const sales = instance.instanceTotals.sales;
      const commissions = instance.instanceTotals.commissions;

      return {
        commissions: {
          monthly: {
            thisYear: sumMonthlyTotal(
              acc.commissions.monthly.thisYear,
              commissions.monthly.thisYear
            ),
            lastYear: sumMonthlyTotal(
              acc.commissions.monthly.lastYear,
              commissions.monthly.lastYear
            )
          },
          thisYearTotal: acc.commissions.thisYearTotal + commissions.thisYearTotal,
          lastYearTotal: acc.commissions.lastYearTotal + commissions.lastYearTotal
        },
        sales: {
          monthly: {
            thisYear: sumMonthlyTotal(acc.sales.monthly.thisYear, sales.monthly.thisYear),
            lastYear: sumMonthlyTotal(acc.sales.monthly.lastYear, sales.monthly.lastYear)
          },
          thisYearTotal: acc.sales.thisYearTotal + sales.thisYearTotal,
          lastYearTotal: acc.sales.lastYearTotal + sales.lastYearTotal
        }
      };
    }, salesCommsTotals);
  }
);

export const allUsersByInstanceSelector = createSelector(instancesAsArraySelector, (instances) => {
  return instances.map(({ instanceName, name, userTotals }) => {
    let users = userTotals.userActivity;
    return users.map((user) => ({ instanceName, name, ...user }));
  });
});

export const allUsersSelector = createSelector(allUsersByInstanceSelector, (allUsersByInstance) => {
  let allUsers = [];

  allUsersByInstance.forEach((users) => {
    allUsers = [...allUsers, ...users];
  });
  const nonSysAdminUsers = allUsers.filter(
    ({ userEmail }) => userEmail !== 'systemadmin@repfabric.com'
  );
  let nonWalletUsers = allUsers.filter(({ userCategory }) => userCategory !== 'WalletUser');
  return { allUsers, nonSysAdminUsers, nonWalletUsers };
});

export const allInstancesUserActivitySelector = createSelector(
  allUsersSelector,
  ({ nonWalletUsers, nonSysAdminUsers }) => {
    const userActivityTotals = nonSysAdminUsers.reduce(
      (acc, user) => sumCommonUserActivity(acc, user),
      emptyUserActivityObj
    );
    const totalNonWalletUsers = nonWalletUsers.length;
    const avgerageUserActivity = avgUserActivity(userActivityTotals, totalNonWalletUsers);

    return { userActivityTotals, avgUserActivity: avgerageUserActivity };
  }
);

export const instanceSalesDailyTotalsSelector = createSelector(
  [currentInstanceSelector],
  ({ sales }) => {
    const byInvoiceDate = sumDayByDateRange({
      array: sales?.last90DaysByInvoiceDate,
      daysToSum: 90,
      itemToSum: 'salesAmount',
      dateSpecificItem: 'invoiceDate'
    });

    const totalByInvoiceDate = byInvoiceDate
      .map(({ salesAmount }) => salesAmount)
      .reduce((prev, next) => prev + next, 0);

    return {
      byInvoiceDate,
      totalByInvoiceDate
    };
  }
);

export const instanceCommissionsDailyTotalsSelector = createSelector(
  [currentInstanceSelector],
  ({ commissions }) => {
    const byCheckDate = sumDayByDateRange({
      array: commissions?.last90DaysByCheckDate,
      daysToSum: 60,
      itemToSum: 'commissionAmount',
      dateSpecificItem: 'checkDate'
    });
    const totalByCheckDate = byCheckDate
      .map(({ commissionAmount }) => commissionAmount)
      .reduce((prev, next) => prev + next, 0);
    return {
      byCheckDate,
      totalByCheckDate
    };
  }
);

/*
walletUsers: {
  nonWalletUserIds: instance.userTotals.userActivity
    .filter(({ userCategory }) => userCategory !== 'WalletUser')
    .map(({ userId }) => userId),
  walletUserIds: instance.userTotals.userActivity
    .filter(({ userCategory }) => userCategory === 'WalletUser')
    .map(({ userId }) => userId)
},
sysAdminUsers: {
  nonSysAdminUserIds: instance.userTotals.userActivity
    .filter(({ userEmail }) => userEmail !== 'systemadmin@repfabric.com')
    .map(({ userId }) => userId),
  sysAdminUserIds: instance.userTotals.userActivity
    .filter(({ userEmail }) => userEmail === 'systemadmin@repfabric.com')
    .map(({ userId }) => userId)
},
*/

/*
export const commissionsSalesSelector = createSelector(
  [instancesByIdSelector, currentInstanceSelector],
  (instancesById, currentInstance) => {
    let { commissions, sales, instanceName, id } = instancesById[currentInstance.id];
    return { commissions, sales, instanceName, id };
  }
);
*/

/*
export const allInstancesTotalCommSalesSelector = createSelector(
  instancesAsArraySelector,
  instances => {
    return instances.reduce(({ instanceTotals: { commissions, sales } }) => ({
      thisYearToal: commissions.thisYearToal,
      lastYearToal: commissions.lastYearToal
    }));
  }
);
*/
/*
export const mostRecentActivityDatesSelector = createSelector([instancesByIdSelector, currentInstanceSelector],(instancesById, currentInstance) => {
  let { commissions, sales, instanceName, id } = instancesById[currentInstance.id];
  return { commissions, sales, instanceName, id };
});*/
