import { client } from './apiClient';

function getInstances() {
  return client(`instances/latest?id=${process.env.NODE_ENV}`);
}
/*
function getInstance({ selectors = 'commissions,sales' }) {
  return client(`instances/latest?selectors=${selectors}`);
}*/

function getInstancesExceptTotals({ selectors = 'allExceptTotals' }) {
  return client(`instances/latest?selectors=${selectors}`);
}

async function getInstanceById(instanceId, selectors = 'commissions,sales') {
  const salesCommReturn = await client(`instances/latest/${instanceId}?selectors=${selectors}`);
  //console.log('salesComReturn ', salesCommReturn);
  return salesCommReturn;
}

function getCommissions(instanceName) {
  return client(`commissions/${instanceName}`);
}

export { getInstances, getInstancesExceptTotals, getInstanceById, getCommissions };
