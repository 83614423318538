import { client } from './apiClient';

function getUsers() {
  return client(`auth0/users`);
}

function getUserById(id) {
  return client(`auth0/users/${id}`);
}

export { getUserById, getUsers };
