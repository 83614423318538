import * as React from 'react';
import { useAnalyticsApi, useAuthorize, useSignOut } from 'react-use-analytics-api';
import { useIsMountedRef } from 'src/utils';
import { getGaAccessToken } from 'src/api';

export default function AuthorizeAnalytics() {
  const [accessToken, setAccessToken] = React.useState(null);
  const isMountedRef = useIsMountedRef();
  const { ready, gapi, authorized, error } = useAnalyticsApi();
  const authorize = useAuthorize(gapi, {
    serverAuth: { access_token: accessToken },
    container: 'authorize-container-id' // 'UA-50627351-2'
  });
  const signOut = useSignOut(gapi);
  // Workaround for API limitation
  const [authorizeCalled, setAuthorizeCalled] = React.useState(false);
  const authDiv = React.useRef(null);
  const hasAuthElements = authDiv.current && authDiv?.current?.children?.length > 0;

  const getAccessToken = React.useCallback(async () => {
    if (isMountedRef.current) {
      const { accessToken } = await getGaAccessToken();
      setAccessToken(accessToken);
    }
  }, [isMountedRef]);

  React.useEffect(() => {
    getAccessToken();
  }, [getAccessToken]);

  React.useEffect(() => {
    if (ready && !error && !authorizeCalled && accessToken) {
      authorize();
      setAuthorizeCalled(true);
    }
  }, [authorizeCalled, authorize, error, ready, accessToken]);

  return (
    <div style={{ display: 'none' }}>
      {!error &&
        (ready && !!gapi ? (
          <div>
            {authorized && (
              <div>
                Logged into Google Analytics! <button onClick={() => signOut()}>Sign Out</button>
              </div>
            )}
            {!authorized && <div> Not logged into Google Analytics</div>}
            <div id="authorize-container-id" ref={authDiv} />
            {!authorized && authorizeCalled && !hasAuthElements && (
              <div> Refresh the page to access analytics.</div>
            )}
          </div>
        ) : (
          <div> Loading...</div>
        ))}
      {error && <div>{error.toString()}</div>}
    </div>
  );
}
