import moment from 'moment';

export const emptyMonthlyTotals = {
  Jan: 0,
  Feb: 0,
  Mar: 0,
  Apr: 0,
  May: 0,
  Jun: 0,
  Jul: 0,
  Aug: 0,
  Sep: 0,
  Oct: 0,
  Nov: 0,
  Dec: 0
};

export const salesCommsTotals = {
  commissions: {
    monthly: {
      thisYear: emptyMonthlyTotals,
      lastYear: emptyMonthlyTotals
    },
    thisYearTotal: 0,
    lastYearTotal: 0
  },
  sales: {
    monthly: {
      thisYear: emptyMonthlyTotals,
      lastYear: emptyMonthlyTotals
    },
    thisYearTotal: 0,
    lastYearTotal: 0
  }
};

export const sumMonthlyTotal = (acc, val) => ({
  Jan: acc['Jan'] + val['Jan'],
  Feb: acc['Feb'] + val['Feb'],
  Mar: acc['Mar'] + val['Mar'],
  Apr: acc['Apr'] + val['Apr'],
  May: acc['May'] + val['May'],
  Jun: acc['Jun'] + val['Jun'],
  Jul: acc['Jul'] + val['Jul'],
  Aug: acc['Aug'] + val['Aug'],
  Sep: acc['Sep'] + val['Sep'],
  Oct: acc['Oct'] + val['Oct'],
  Nov: acc['Nov'] + val['Nov'],
  Dec: acc['Dec'] + val['Dec']
});

export const avgUserActivity = (userActivityTotals, totalNonWalletUsers) => ({
  contactsCreatedWeek: userActivityTotals.contactsCreatedWeek / totalNonWalletUsers,
  contactsCreatedMonth: userActivityTotals.contactsCreatedMonth / totalNonWalletUsers,
  contactsCreatedQuarter: userActivityTotals.contactsCreatedQuarter / totalNonWalletUsers,
  ajCreatedWeek: userActivityTotals.ajCreatedWeek / totalNonWalletUsers,
  ajCreatedMonth: userActivityTotals.ajCreatedMonth / totalNonWalletUsers,
  ajCreatedQuarter: userActivityTotals.ajCreatedQuarter / totalNonWalletUsers,
  oppsCreatedWeek: userActivityTotals.oppsCreatedWeek / totalNonWalletUsers,
  oppsCreatedMonth: userActivityTotals.oppsCreatedMonth / totalNonWalletUsers,
  oppsCreatedQuarter: userActivityTotals.oppsCreatedQuarter / totalNonWalletUsers
});

/**
 * Sums specific values of the input array on a daily resolution.
 *
 * @param {Object} configs - Configs on how to sum array of objects
 * @param {Array.<Object>} configs.array - Array of objects
 * @param {number} [configs.daysToSum=0] - Amount of days into the past to sum
 * @param {string} [configs.itemToSum='salesAmount'] - Object key that will be summed
 * @param {string} [configs.dateSpecificItem='invoiceDate'] - Object key that is date specific
 * @param {?string} [configs.nonDateSpecificItem=null] - If adding values that are not
 *  dependent on a date. For example, users or companies.
 */
export const sumDayByDateRange = ({
  array = [{}],
  daysToSum = 0,
  itemToSum = 'salesAmount',
  dateSpecificItem = 'invoiceDate',
  nonDateSpecificItem = null
}) => {
  let tempArr = [];
  if (array.length < 1) {
    let tempDate = moment().subtract(daysToSum, 'days');

    const emptyArr = new Array(daysToSum);
    emptyArr.forEach((val) => {
      tempArr.push({ [itemToSum]: 0, [dateSpecificItem]: tempDate.add(1, 'day').format() });
    });
    return tempArr;
  }
  // find unique non date specific items and sum the itemToSum
  if (nonDateSpecificItem) {
    const uniqueItems = [...new Set(array.slice().map((item) => item[nonDateSpecificItem]))].sort();

    // Sum the itemToSum over all unique dates
    uniqueItems.forEach((uniqueItem) => {
      let tempSum = 0;
      //const tempDate = moment(date);
      array.forEach((item) => {
        if (uniqueItem === item[nonDateSpecificItem]) {
          tempSum += item[itemToSum];
        }
      });

      tempArr.push({ [itemToSum]: tempSum, [nonDateSpecificItem]: uniqueItem });
      tempSum = 0;
    });

    return tempArr;
  }

  // Get unique dates
  const uniqueDates = [
    ...new Set(array.slice().map((item) => moment(item[dateSpecificItem]).format('MM-DD-YYYY')))
  ].sort();

  // Sum the itemToSum over all unique dates
  uniqueDates.forEach((date) => {
    let tempSum = 0;
    const tempDate = moment(date);
    array.forEach((item) => {
      if (tempDate.isSame(moment(item[dateSpecificItem]), 'day')) {
        tempSum += item[itemToSum];
      }
    });

    tempArr.push({ [itemToSum]: tempSum, [dateSpecificItem]: moment(tempDate).format() });
    tempSum = 0;
  });
  return tempArr;
};
