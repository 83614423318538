//

import { createSlice } from '@reduxjs/toolkit';
// import { getAllDataConversionRecords, updateDataConversionRecordById } from 'src/api';
import { v4 as uuidv4 } from 'uuid';

const dataConversionInitialState = {
  dataConversionRecordsByType: {},
  formats: {},
  files: [{ fileName: 'blank', fileId: '' }],
  currentFile: { fileName: 'blank', fileId: '' },
  filesById: {
    blank: {
      title: '',
      lastModified: '',
      size: '',
      type: '',
      columns: [''],
      rowsById: {},
      metadata: {}
    }
  },
  columnsToMatch: [
    {
      finalFileTitle: '',
      finalFileId: '',
      columns: {
        columnsToCombine: [{ fileTitle: '', fileId: '', columns: [{}] }]
      }
    }
  ],
  outputFile: [],
  hasFiles: false,
  isLoading: false,
  error: null
};

function startLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

const dataConversion = createSlice({
  name: 'dataConversion',
  initialState: dataConversionInitialState,
  reducers: {
    //getDataConversionRecordsStart: startLoading,
    //updateDataConversionRecordStart: startLoading,
    /*getDataConversionRecordsSuccess(state, { payload }) {
      const dataConversionRecords = payload;
      dataConversionRecords.forEach((dataConversionRecords) => {
        state.dataConversionRecordsByType[dataConversionRecords.type] = dataConversionRecords;

        // Set temp record for saving later
        state.tempDataConversionRecordsByType[dataConversionRecords.type] = {
          rates: dataConversionRecords.rates,
          firstSixMonths: {
            rates: dataConversionRecords.firstSixMonths.rates
            //ranges: dataConversionRecords.firstSixMonths.ranges
          }
        }; 
      });
      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },*/
    setCurrentFile(state, { payload }) {
      console.log('currentFile', payload);
      state.currentFile = payload;
    },
    setFileRecords(state, { payload }) {
      console.log('payload', payload);
      const { columns, fileMeta, rows, fileNum } = payload;

      /*
      {
      title: '',
      lastModified: '',
      size: '',
      type: '',
      columns: [''],
      rows: [{}],
      metadata: {}
    } */
      const fileId = uuidv4();
      if (!rows[0].uuid) {
        console.log(`Rows do not have uuid: row`, rows[0]);
      }
      const rowsWithId = rows.map((row) => ({
        ...row,
        uuid: uuidv4()
      }));

      const rowsById = {};

      rowsWithId.forEach((row) => {
        rowsById[row.uuid] = row;
      });

      state.filesById[fileId] = {
        name: fileMeta.name,
        lastModified: fileMeta.lastModified,
        size: fileMeta.size,
        type: fileMeta.type,
        columns: columns,
        rowsById: rowsById,
        metadata: {}
      };

      const files = state.files.filter(({ fileName }) => fileName !== 'blank');
      //const currentFile = currentFile;
      state.files = [...files, { fileName: fileMeta.name, fileId }];
      state.currentFile = { fileName: fileMeta.name, fileId };
      /*const currentRecord = state.dataConversionRecordsByType[type];
      if (firstSixMonths) {
        state.tempDataConversionRecordsByType[type] = {
          rates: currentRecord.rates,
          firstSixMonths: {
            rates: [0, newMidValue, newHighValue]
            //ranges: currentRecord.firstSixMonths.ranges
          }
        };
      } else {
        state.tempDataConversionRecordsByType[type] = {
          rates: [0, newMidValue, newHighValue],
          firstSixMonths: {
            rates: currentRecord.firstSixMonths.rates
          }
        };
      }*/

      state.hasFiles = true;
      state.isLoading = false;
      state.error = null;
    }
    /*setTempDataConversionRecord(state, { payload }) {
      const { type, firstSixMonths, newMidValue, newHighValue } = payload;
      const currentRecord = state.dataConversionRecordsByType[type];
      if (firstSixMonths) {
        state.tempDataConversionRecordsByType[type] = {
          rates: currentRecord.rates,
          firstSixMonths: {
            rates: [0, newMidValue, newHighValue]
            //ranges: currentRecord.firstSixMonths.ranges
          }
        };
      } else {
        state.tempDataConversionRecordsByType[type] = {
          rates: [0, newMidValue, newHighValue],
          firstSixMonths: {
            rates: currentRecord.firstSixMonths.rates
          }
        };
      }

      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    updateDataConversionRecordSuccess(state, { payload }) {
      // previous
      const { updates, current } = payload;
      if (updates?.nModified === 1) {
        state.dataConversionRecordsByType[current.type] = current;
        state.tempDataConversionRecordsByType[current.type] = {
          rates: current.rates,
          firstSixMonths: {
            rates: current.firstSixMonths.rates
            //ranges: current.firstSixMonths.ranges
          }
        };
      }
      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },*/
    //getDataConversionRecordsFailure: loadingFailed,
    //updateDataConversionRecordFailure: loadingFailed
  }
});

export const { setFileRecords, setCurrentFile } = dataConversion.actions;

export default dataConversion.reducer;
/*
export const fetchDataConversionRecords = () => async (dispatch) => {
  try {
    dispatch(getDataConversionRecordsStart());
    const dataConversionRecords = await getAllDataConversionRecords();
    console.log('data conversion records redux', dataConversionRecords);
    dispatch(getDataConversionRecordsSuccess(dataConversionRecords));
  } catch (err) {
    dispatch(getDataConversionRecordsFailure(err.toString()));
  }
};

export const updateDataConversionRecord = ({ id, update }) => async (dispatch) => {
  try {
    dispatch(updateDataConversionRecordStart());
    const dataConversionRecords = await updateDataConversionRecordById(id, update);
    dispatch(updateDataConversionRecordSuccess(dataConversionRecords));
  } catch (err) {
    dispatch(updateDataConversionRecordFailure(err.toString()));
  }
};*/
/*
export const saveMathTable = () => async (dispatch) => {
  try {
    dispatch(getMathTableStart());
    const dataConversion = await getMathTable();
    dispatch(getMathTableSuccess(dataConversion));
  } catch (err) {
    dispatch(getMathTableFailure(err.toString()));
  }
};
*/
