import React from 'react';
import { CircularProgress, makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: 50,
    padding: theme.spacing(3)
  }
}));

function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box>
        <CircularProgress />
      </Box>
    </div>
  );
}

export default React.memo(Loading);
