import React from 'react';
import PropTypes from 'prop-types';

import CONSTS from 'src/constants';
const SettingsContext = React.createContext();

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (error) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error

    const defaultSettings = {
      direction: 'ltr',
      responsiveFontSizes: true,
      theme: 'ONE_DARK',
      hideUnusedLinks: true,
      loadGaData: CONSTS.isDev ? false : true
    };
    settings = defaultSettings;
  }

  return settings;
};

export const storeSettings = (settings) => {
  localStorage.setItem('settings', JSON.stringify(settings));
};

function SettingsProvider({ settings, children }) {
  const defaultSettings = {
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: CONSTS.themes.oneDark,
    hideUnusedLinks: true,
    loadGaData: CONSTS.isDev ? false : true
  };
  const [currentSettings, setCurrentSettings] = React.useState(settings ?? defaultSettings);

  function saveSettings(updatedSettings = {}) {
    const mergedSettings = { ...currentSettings, ...updatedSettings };

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  }

  React.useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};

function useSettings() {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }
  return context;
}

export { SettingsProvider, useSettings };
