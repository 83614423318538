import React from 'react';
//import { useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth0 } from 'src/context/auth0-context';

// TODO: look into making this into AuthGuard
// const AuthRoute = ({ component: Component, path, render, ...rest }) => {
const AuthRoute = ({ children }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const history = useHistory();

  //const account = useSelector((state) => state.account); // orig AuthRoute

  /*if (!account.user) { // orig AuthRoute
    return <Redirect to="/login" />;
  }*/

  React.useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      /*await loginWithRedirect({
        appState: { targetUrl: path }
      });*/
      if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'production-dev') {
        history.push(`${window.location.origin}/auth`);
      } else {
        history.push('/auth');
      }
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect, history]);

  return children; // testing this so hooks can be used
  /*if (render) {
    return isAuthenticated === true ? render({ ...rest, path }) : null;
  } else {
    render = props =>
      isAuthenticated === true ? <Component path={path} {...rest} /> : null;

    return <Route path={path} render={render} {...rest} />;
  }*/
  // <Route path={path} render={render} {...rest} /> orig from Auth0
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};

export default withRouter(AuthRoute);
