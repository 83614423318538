import { createSlice } from '@reduxjs/toolkit';
//import { Links } from 'parse-link-header'
import moment from 'moment';
import { getInstances, getInstanceById } from 'src/api';
//import { Instance, InstancesResult, getInstance, getInstances } from 'api/githubAPI'
import { sumObjectVals } from 'src/utils';
import {
  emptyUserActivityObj,
  sumCommonUserActivity,
  formatCommonUserActivity
} from 'src/components/user-activity/helpers';
import CONSTS from 'src/constants';

const instancesInitialState = {
  instancesById: {},
  currentPageInstances: [],
  currentInstance: { hasFetchedData: false },
  cronRuntime: moment().subtract(10, 'years').format(),
  pageCount: 0,
  pageLinks: {},
  hasFetchedData: false,
  isLoading: false,
  error: null
};

function startLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

function startInstanceLoading(state) {
  state.currentInstance.hasFetchedData = false;
  state.currentInstance.isLoading = true;
}

function loadingInstanceFailed(state, action) {
  state.currentInstance.isLoading = false;
  state.currentInstance.error = action.payload;
}

function setCurrentInstance(state, action) {
  state.currentInstance = action.payload;
}

const instances = createSlice({
  name: 'instances',
  initialState: instancesInitialState,
  reducers: {
    getInstanceStart: startInstanceLoading,
    getInstancesStart: startLoading,
    getCurrentInstance: setCurrentInstance,
    getInstanceSuccess(state, { payload }) {
      const { id, commissions, sales } = payload;
      // state.instancesById[id].commissions = commissions;
      // state.instancesById[id].sales = sales;
      state.currentInstance.id = id;
      state.currentInstance.commissions = commissions;
      state.currentInstance.sales = sales;
      state.currentInstance.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    getInstancesSuccess(state, { payload }) {
      const { instances, cronRuntime } = payload;

      instances
        .filter(({ instanceName }) => instanceName !== 'prod1')
        .filter(({ instanceName }) => instanceName !== 'aimr')
        .forEach((instance) => {
          state.instancesById[instance.id] = instance;
        });

      //state.totals = instancesCommSalesTotals;
      state.cronRuntime = cronRuntime;
      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    getInstanceFailure: loadingInstanceFailed,
    getInstancesFailure: loadingFailed
  }
});

export const {
  getInstancesStart,
  getInstancesSuccess,
  getInstanceStart,
  getInstanceSuccess,
  getInstanceFailure,
  getInstancesFailure,
  getCurrentInstance
} = instances.actions;

export default instances.reducer;

export const fetchInstances = () => async (dispatch) => {
  try {
    dispatch(getInstancesStart());
    let instances = await getInstances();

    if (CONSTS.isDev) {
      instances = instances.slice(5, 10);
    }

    //console.log(` instances, cronRuntime`, instances);
    const formattedInstances = instances.map((instance) => {
      return {
        instanceName: instance.instanceName,
        name: instance.name,
        type: instance.type,
        rank: instance.rank,
        accountManager: instance.accountManager,
        region: instance.region,
        createdDate: instance.createdDate,
        updatedDate: instance.updatedDate,
        launchDate: instance.launchDate,
        affectedByCovid19: instance.affectedByCovid19,
        id: instance.id,
        instanceTotals: {
          importLog: {
            batchNumber: instance.instanceTotals.importLog.batchNumber,
            importDate: instance.instanceTotals.importLog.importDate
          },
          userActivity: instance.userTotals.userActivity
            .map((user) => formatCommonUserActivity(user))
            .reduce((acc, user) => sumCommonUserActivity(acc, user), emptyUserActivityObj),
          commissions: {
            monthly: instance.commissions.monthly,
            thisYearTotal: sumObjectVals(instance.commissions.monthly.thisYear),
            lastYearTotal: sumObjectVals(instance.commissions.monthly.lastYear)
          },
          sales: {
            monthly: instance.sales.monthly,
            thisYearTotal: sumObjectVals(instance.sales.monthly.thisYear),
            lastYearTotal: sumObjectVals(instance.sales.monthly.lastYear)
          }
        },
        userTotals: {
          //salesTeams: instance.userTotals.userActivity[0].salesTeams,
          userActivity: instance.userTotals.userActivity
        }
      };
    });
    dispatch(
      getInstancesSuccess({ instances: formattedInstances, cronRuntime: instances[0].cronRuntime })
    );
  } catch (err) {
    dispatch(getInstancesFailure(err.toString()));
  }
};

export const fetchInstance = (instanceId) => async (dispatch) => {
  try {
    dispatch(getInstanceStart());
    const instance = await getInstanceById(instanceId);
    // console.log('instance from fetch instance by id', instance);

    dispatch(getInstanceSuccess(instance));
  } catch (err) {
    dispatch(getInstanceFailure(err.toString()));
  }
};
