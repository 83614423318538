//

import { createSlice } from '@reduxjs/toolkit';
import { getAllMathTableRecords, updateMathTableRecordById } from 'src/api';

const mathTableInitialState = {
  mathTableRecordsByType: {},
  tempMathTableRecordsByType: {
    salesCommReports: {
      rates: [0, 0.6, 0.75],
      firstSixMonths: {
        rates: [0, 0.6, 0.75]
      }
    },
    oppAjReports: {
      rates: [0, 0.6, 0.75],
      firstSixMonths: {
        rates: [0, 0.6, 0.75]
      }
    },
    userActivityReports: {
      rates: [0, 0.6, 0.75],
      firstSixMonths: {
        rates: [0, 0.6, 0.75]
      }
    },
    salesAmounts: {
      rates: [0, 0.6, 0.75],
      firstSixMonths: {
        rates: [0, 0.6, 0.75]
      }
    },
    commissionAmounts: {
      rates: [0, 0.6, 0.75],
      firstSixMonths: {
        rates: [0, 0.6, 0.75]
      }
    }
  },
  hasFetchedData: false,
  isLoading: false,
  error: null
};

function startLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

const mathTable = createSlice({
  name: 'mathTable',
  initialState: mathTableInitialState,
  reducers: {
    getMathTableRecordsStart: startLoading,
    updateMathTableRecordStart: startLoading,
    getMathTableRecordsSuccess(state, { payload }) {
      const mathTableRecords = payload;
      mathTableRecords.forEach((mathTableRecord) => {
        state.mathTableRecordsByType[mathTableRecord.type] = mathTableRecord;

        // Set temp record for saving later
        state.tempMathTableRecordsByType[mathTableRecord.type] = {
          rates: mathTableRecord.rates,
          firstSixMonths: {
            rates: mathTableRecord.firstSixMonths.rates
            //ranges: mathTableRecord.firstSixMonths.ranges
          }
        };
      });
      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    setTempMathTableRecord(state, { payload }) {
      const { type, firstSixMonths, newMidValue, newHighValue } = payload;
      const currentRecord = state.mathTableRecordsByType[type];
      if (firstSixMonths) {
        state.tempMathTableRecordsByType[type] = {
          rates: currentRecord.rates,
          firstSixMonths: {
            rates: [0, newMidValue, newHighValue]
            //ranges: currentRecord.firstSixMonths.ranges
          }
        };
      } else {
        state.tempMathTableRecordsByType[type] = {
          rates: [0, newMidValue, newHighValue],
          firstSixMonths: {
            rates: currentRecord.firstSixMonths.rates
          }
        };
      }

      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    updateMathTableRecordSuccess(state, { payload }) {
      // previous
      const { updates, current } = payload;
      if (updates?.nModified === 1) {
        state.mathTableRecordsByType[current.type] = current;
        state.tempMathTableRecordsByType[current.type] = {
          rates: current.rates,
          firstSixMonths: {
            rates: current.firstSixMonths.rates
            //ranges: current.firstSixMonths.ranges
          }
        };
      }
      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    getMathTableRecordsFailure: loadingFailed,
    updateMathTableRecordFailure: loadingFailed
  }
});

export const {
  getMathTableRecordsStart,
  updateMathTableRecordStart,
  getMathTableRecordsSuccess,
  setTempMathTableRecord,
  updateMathTableRecordSuccess,
  getMathTableRecordsFailure,
  updateMathTableRecordFailure
} = mathTable.actions;

export default mathTable.reducer;

export const fetchMathTableRecords = () => async (dispatch) => {
  try {
    dispatch(getMathTableRecordsStart());
    const mathTableRecords = await getAllMathTableRecords();
    console.log('math table records redux', mathTableRecords);
    dispatch(getMathTableRecordsSuccess(mathTableRecords));
  } catch (err) {
    dispatch(getMathTableRecordsFailure(err.toString()));
  }
};

export const updateMathTableRecord = ({ id, update }) => async (dispatch) => {
  try {
    dispatch(updateMathTableRecordStart());
    const mathTableRecord = await updateMathTableRecordById(id, update);
    dispatch(updateMathTableRecordSuccess(mathTableRecord));
  } catch (err) {
    dispatch(updateMathTableRecordFailure(err.toString()));
  }
};
/*
export const saveMathTable = () => async (dispatch) => {
  try {
    dispatch(getMathTableStart());
    const mathTable = await getMathTable();
    dispatch(getMathTableSuccess(mathTable));
  } catch (err) {
    dispatch(getMathTableFailure(err.toString()));
  }
};
*/
