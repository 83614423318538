import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    maxHeight: 25,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: 'uppercase'
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: fade(theme.palette.primary.main, 0.08)
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: fade(theme.palette.secondary.main, 0.08)
  },
  error: {
    color: theme.palette.error.contrastText, //theme.palette.error.main,
    backgroundColor:
      theme.palette.type === 'light'
        ? fade(theme.palette.error.main, 0.95)
        : theme.palette.error.light
  },
  success: {
    color: theme.palette.success.contrastText, // theme.palette.success.main
    backgroundColor:
      theme.palette.type === 'light'
        ? fade(theme.palette.success.main, 0.95)
        : theme.palette.success.light // fade(theme.palette.success.main, 0.08)
  },
  warning: {
    color: theme.palette.warning.contrastText,
    backgroundColor:
      theme.palette.type === 'light'
        ? fade(theme.palette.warning.main, 0.95)
        : theme.palette.warning.light // fade(theme.palette.warning.main, 0.08)
  }
}));

function Label({ className, color, children, style, ...rest }) {
  const classes = useStyles();

  return (
    <span
      className={clsx(
        classes.root,
        {
          [classes[color]]: color
        },
        className
      )}
      {...rest}
    >
      {children}
    </span>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success'])
};

Label.defaultProps = {
  className: '',
  color: 'secondary'
};

export default Label;
