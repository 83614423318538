import { client } from './apiClient';

function getAllMathTableRecords() {
  return client(`math-table/all`);
}

function updateMathTableRecordById(id, update) {
  return client(`math-table/update/${id}`, { body: update });
}

export { getAllMathTableRecords, updateMathTableRecordById };
