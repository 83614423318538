import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import {
  // AnalyticsDashboard,
  // ActiveUsersChart,
  // TableChart,
  // LineChart,
  // GeoChart,
  // BarChart,
  // ColumnChart
  // SessionsBySourceChart,
  DataChart
} from 'react-analytics-charts';
import { useAnalyticsApi } from 'react-use-analytics-api';

import { Box, CardContent, useTheme } from '@material-ui/core';

import Loading from 'src/components/Loading';

import MainCard from 'src/components/MainCard';

const defaultStartDate = moment().subtract(14, 'days').format('YYYY-MM-DD');
const defaultEndDate = moment().format('YYYY-MM-DD');

//const viewId = 'ga:186585986';
// const instanceNameCustomDimensionNum = 2;
//const userNameCustomDimensionNum = 4,

// chart types: "LINE" | "COLUMN" | "BAR" | "PIE" | "TABLE" | "GEO"
function GoogleChart({
  className,
  title,
  startDate,
  endDate,
  metrics,
  dimensions,
  sort,
  filters,
  subtitle,
  segment,
  chartType,
  chartId,
  output
}) {
  const theme = useTheme();
  const [chartQuery, setChartQuery] = React.useState(null);
  const finalChartId = chartId ?? uuidv4();
  const { ready, gapi, authorized } = useAnalyticsApi();
  const { loadGaData, viewId } = useSelector((state) => state.settings.ga);

  React.useEffect(() => {
    if (loadGaData && chartQuery === null) {
      let buildQuery = {
        ids: viewId,
        'start-date': startDate,
        'end-date': endDate,
        metrics,
        'include-empty-rows': false
      };
      if (dimensions) buildQuery.dimensions = dimensions;
      if (sort) buildQuery['sort'] = sort;
      if (filters) buildQuery.filters = filters;
      // dimensions: `ga:dimension${instanceNameCustomDimensionNum}`,
      // sort: `-ga:sessions`,
      // filters: `ga:sessions<=100`

      setChartQuery(buildQuery);
    }
  }, [startDate, endDate, metrics, dimensions, sort, filters, loadGaData, viewId, chartQuery]);

  return (
    <MainCard title={title} subtitle={subtitle} className={className}>
      {loadGaData && ready && gapi && authorized && chartQuery ? (
        <CardContent
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: '-50px',
            marginBottom: '-20px'
          }}
        >
          <Box pt={0} pb={0} pr={2} pl={2}>
            <DataChart
              style={{ marginLeft: '0', width: '100%', height: '100%' }}
              gapi={gapi}
              query={chartQuery}
              chart={{
                type: chartType,
                container: finalChartId,

                options: {
                  //chartArea: { width: '100%', height: '100%' },
                  legend: {
                    position: 'top',
                    textStyle: { color: theme.palette.text.primary }
                  },
                  tooltip: {
                    textStyle: {
                      color: theme.palette.text.primary
                    },
                    background: theme.palette.background.dark
                  },
                  height: 500,
                  paddingTop: 0,
                  width: '100%',
                  colors: [
                    theme.palette.primary.main,
                    theme.palette.secondary.dark,
                    theme.palette.tertiary.dark
                  ],
                  pointSize: 3,
                  lineWidth: 2,
                  axisTitlesPosition: 'out',
                  hAxis: {
                    textStyle: {
                      color: theme.palette.text.primary
                      // fontSize: 12
                    }
                  }
                }
              }}
            />
          </Box>
        </CardContent>
      ) : (
        <Loading />
      )}
    </MainCard>
  );
}

GoogleChart.propTypes = {
  chartType: PropTypes.oneOf(['LINE', 'COLUMN', 'BAR', 'PIE', 'TABLE', 'GEO']),
  title: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  metrics: PropTypes.string.isRequired,
  output: PropTypes.oneOf(['dataTable', 'json']).isRequired,
  dimensions: PropTypes.string,
  sort: PropTypes.any,
  filters: PropTypes.any
};

GoogleChart.defaultProps = {
  title: 'Loading Data...',
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  metrics: null,
  dimensions: null,
  sort: null,
  filters: null,
  segment: null,
  chartType: 'LINE',
  output: 'dataTable',
  subtitle: ''
};

export default GoogleChart;
