import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon } from '@material-ui/core';
import Label from 'src/components/Label';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import CONSTS from 'src/constants';
import Account from './Account';

// import Contacts from './Contacts';
// import Notifications from './Notifications';
// import Search from './Search';
import Settings from './Settings';

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: theme.zIndex.drawer + 100,
      ...(theme.name === CONSTS.themes.light
        ? {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main
          }
        : {}),
      ...(theme.name === CONSTS.themes.oneDark
        ? {
            backgroundColor: theme.palette.background.default
          }
        : {})
    },
    toolbar: {
      minHeight: 64
    },
    logoLabel: {
      marginLeft: 10
    }
  }));
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton className={classes.menuButton} color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Label className={classes.logoLabel}>{CONSTS?.appVersion}</Label>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/*<Search />*/}
        {/*<Contacts />*/}
        {/*<Notifications />*/}
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
