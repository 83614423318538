import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import moment from 'moment';
export const axiosInstance = axios.create();
export const mockInstance = new AxiosMockAdapter(axiosInstance, { delayResponse: 0 });

// =====================================================================
// Promise based wait
// =====================================================================
export const wait = (time) => new Promise((res) => setTimeout(res, time));

// =====================================================================
// Objects and arrays
// =====================================================================

// Sum values of object
// TODO: Typescript
/*

interface ObjectToSum {
  key: string;
  val: number;
}
*/
export const sumObjectVals = (obj) => Object.values(obj).reduce((acc, val) => acc + val, 0);

// Get Object from array
export const objFromArray = (arr, key = 'id') =>
  arr.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});

// =====================================================================
// Generates an array of label colors for tables
// =====================================================================

const generateNumLabelColors = (numThreshold1, numThreshold2) => {
  let numLabelColors = {};
  Array.from(Array(12000), (x, idx) => idx - 1).forEach((i) => {
    if (i < numThreshold1) numLabelColors[i] = 'error';
    if (i >= numThreshold1 && i < numThreshold2) numLabelColors[i] = 'warning';
    if (i >= numThreshold2) numLabelColors[i] = 'success';
  });
  return numLabelColors;
};

export const numLabelColors = generateNumLabelColors(5, 10);

/* eslint-disable no-restricted-properties */
export const bytesToSize = (bytes = 0, decimals) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// =====================================================================
// Google Analytics
// =====================================================================

function track(...args) {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!window.gtag) {
    return;
  }

  window.gtag(...args);
}

function pageview(props) {
  track('config', process.env.REACT_APP_GA_MEASUREMENT_ID, props);
}

function event(type, props) {
  track('event', type, props);
}

export const trackWrapper = { pageview, event };

// =====================================================================
// String Manipulation
// =====================================================================

export const toCapitalCase = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/* string.anyStringToTitleCase()
  Handles inputs:
    "space separated", camelCase, ClassCase, UPPERCASE, snake_case, dash-case, object.case.
  eg: 'string-toConvert_has a.very ComplexStructure'.anyStringToTitleCase()
    returns: 'String To Convert Has A Very Complex Structure'
 */
const STRING_DECAMELIZE_REGEXP = /([a-z\d])([A-Z])/g;
const STRING_SEPARATORS_REGEXP = /(-|_|\.)/g;

export const anyStringToTitleCase = (str = '') => {
  return str
    .replace(STRING_DECAMELIZE_REGEXP, '$1 $2')
    .replace(STRING_SEPARATORS_REGEXP, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

/* string.toTitleCase()
  Handles inputs:
    UPPERCASE, "space separated".
  eg: 'STRING TO CONVERT'.toTitleCase() returns: 'String To Convert'
 */
export const toTitleCase = (str = '') => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

// Get Initials from Full Name

export const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');

/**
 *
 *
 * @param {*} {
 *   array = [{}],
 *   daysToSum = 0,
 *   itemToSum = 'salesAmount',
 *   dateSpecificItem = 'invoiceDate',
 *   nonDateSpecificItem = null
 * }
 * @returns
 */
export const sumDayByDateRange = ({
  array = [],
  daysToSum = 0,
  itemToSum = 'salesAmount',
  dateSpecificItem = 'invoiceDate',
  nonDateSpecificItem = null
}) => {
  let tempArr = [];
  if (array.length < 1) {
    let tempDate = moment().subtract(daysToSum, 'days');

    const emptyArr = new Array(daysToSum);
    emptyArr.forEach((val) => {
      tempArr.push({ [itemToSum]: 0, [dateSpecificItem]: tempDate.add(1, 'day').format() });
    });
    return tempArr;
  }
  // find unique non date specific items and sum the itemToSum
  if (nonDateSpecificItem) {
    const uniqueItems = [...new Set(array.slice().map((item) => item[nonDateSpecificItem]))].sort();

    // Sum the itemToSum over all unique dates
    uniqueItems.forEach((uniqueItem) => {
      let tempSum = 0;
      //const tempDate = moment(date);
      array.forEach((item) => {
        if (uniqueItem === item[nonDateSpecificItem]) {
          tempSum += item[itemToSum];
        }
      });

      tempArr.push({ [itemToSum]: tempSum, [nonDateSpecificItem]: uniqueItem });
      tempSum = 0;
    });

    return tempArr;
  }

  // Get unique dates
  const uniqueDates = [
    ...new Set(array.slice().map((item) => moment(item[dateSpecificItem]).format('MM-DD-YYYY')))
  ].sort();

  // Sum the itemToSum over all unique dates
  uniqueDates.forEach((date) => {
    let tempSum = 0;
    const tempDate = moment(date);
    array.forEach((item) => {
      if (tempDate.isSame(moment(item[dateSpecificItem]), 'day')) {
        tempSum += item[itemToSum];
      }
    });

    tempArr.push({ [itemToSum]: tempSum, [dateSpecificItem]: moment(tempDate).format() });
    tempSum = 0;
  });
  return tempArr;
};
