import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';

import { createBrowserHistory } from 'history';

import { Auth0Provider } from './auth0-context';
import { useSettings } from './settings-context';
import { createTheme, GlobalStyles } from '../styles';
import Routes from 'src/routes';
import CONSTS from 'src/constants';
import Auth from 'src/components/Auth';

const history = createBrowserHistory();

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const onRedirectCallback = (appState) => {
  //history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  history.push(`/auth`);
};

function AppProviders({ children }) {
  const { settings } = useSettings();
  const theme = createTheme(settings, CONSTS);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router>
              <Auth>
                <Auth0Provider
                  domain={CONSTS.auth0.appDomain}
                  client_id={CONSTS.auth0.appClientId}
                  audience={CONSTS.auth0.apiAudience}
                  redirect_uri={`${window.location.origin}/auth`}
                  onRedirectCallback={onRedirectCallback}
                  scope={'openid profile email app_metadata user_metadata'}
                >
                  <GlobalStyles />
                  {children}
                  <Routes />
                </Auth0Provider>
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export { AppProviders };
