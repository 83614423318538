import React from 'react';
import { makeStyles } from '@material-ui/core';
import { numLabelColors } from 'src/utils';
import Label from 'src/components/Label';

const emptyUserActivityObj = {
  contactsCreatedWeek: 0,
  contactsCreatedMonth: 0,
  contactsCreatedQuarter: 0,
  ajCreatedWeek: 0,
  ajCreatedMonth: 0,
  ajCreatedQuarter: 0,
  oppsCreatedWeek: 0,
  oppsCreatedMonth: 0,
  oppsCreatedQuarter: 0
};

const userActivityLabels = [
  'Contacts-W',
  'Contacts-M',
  'Contacts-Q',
  'AJ-W',
  'AJ-M',
  'AJ-Q',
  'Opps-W',
  'Opps-M',
  'Opps-Q'
];
const commonUserActivityColumns = [
  {
    field: 'contactsCreatedWeek',
    type: 'numeric',
    align: 'center',
    title: 'Contacts-Week'
  },
  {
    field: 'contactsCreatedMonth',
    type: 'numeric',
    align: 'center',
    title: 'Contacts-Month'
  },
  {
    field: 'contactsCreatedQuarter',
    type: 'numeric',
    align: 'center',
    title: 'Contacts-Quarter'
  },
  {
    field: 'ajCreatedWeek',
    type: 'numeric',
    align: 'center',
    title: 'AJ-Week'
  },
  {
    field: 'ajCreatedMonth',
    type: 'numeric',
    align: 'center',
    title: 'AJ-Month'
  },
  {
    field: 'ajCreatedQuarter',
    type: 'numeric',
    align: 'center',
    title: 'AJ-Quarter'
  },
  {
    field: 'oppsCreatedWeek',
    type: 'numeric',
    align: 'center',
    title: 'Opps-Week'
  },
  {
    field: 'oppsCreatedMonth',
    type: 'numeric',
    align: 'center',
    title: 'Opps-Month'
  },
  {
    field: 'oppsCreatedQuarter',
    type: 'numeric',
    align: 'center',
    title: 'Opps-Quarter'
  }
];

const commonUserActivityColumnsWithLabels = [
  {
    field: 'contactsCreatedWeek',
    type: 'numeric',
    title: 'Contacts-Week',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.contactsCreatedWeek]}>
        {rowData.contactsCreatedWeek}
      </Label>
    )
  },
  {
    field: 'contactsCreatedMonth',
    type: 'numeric',
    title: 'Contacts-Month',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.contactsCreatedMonth]}>
        {rowData.contactsCreatedMonth}
      </Label>
    )
  },
  {
    field: 'contactsCreatedQuarter',
    type: 'numeric',
    title: 'Contacts-Quarter',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.contactsCreatedQuarter]}>
        {rowData.contactsCreatedQuarter}
      </Label>
    )
  },
  {
    field: 'ajCreatedWeek',
    type: 'numeric',
    title: 'AJ-Week',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.ajCreatedWeek]}>{rowData.ajCreatedWeek}</Label>
    )
  },
  {
    field: 'ajCreatedMonth',
    type: 'numeric',
    title: 'AJ-Month',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.ajCreatedMonth]}>{rowData.ajCreatedMonth}</Label>
    )
  },
  {
    field: 'ajCreatedQuarter',
    type: 'numeric',
    title: 'AJ-Quarter',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.ajCreatedQuarter]}>{rowData.ajCreatedQuarter}</Label>
    )
  },
  {
    field: 'oppsCreatedWeek',
    type: 'numeric',
    title: 'Opps-Week',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.oppsCreatedWeek]}>{rowData.oppsCreatedWeek}</Label>
    )
  },
  {
    field: 'oppsCreatedMonth',
    type: 'numeric',
    title: 'Opps-Month',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.oppsCreatedMonth]}>{rowData.oppsCreatedMonth}</Label>
    )
  },
  {
    field: 'oppsCreatedQuarter',
    type: 'numeric',
    title: 'Opps-Quarter',
    align: 'center',
    render: (rowData) => (
      <Label color={numLabelColors[rowData.oppsCreatedQuarter]}>{rowData.oppsCreatedQuarter}</Label>
    )
  }
];

function formatCommonUserActivity(userActivity) {
  return {
    contactsCreatedWeek: userActivity.contactsCreatedWeek,
    contactsCreatedMonth: userActivity.contactsCreatedMonth,
    contactsCreatedQuarter: userActivity.contactsCreatedQuarter,
    ajCreatedWeek: userActivity.ajCreatedWeek,
    ajCreatedMonth: userActivity.ajCreatedMonth,
    ajCreatedQuarter: userActivity.ajCreatedQuarter,
    oppsCreatedWeek: userActivity.oppsCreatedWeek,
    oppsCreatedMonth: userActivity.oppsCreatedMonth,
    oppsCreatedQuarter: userActivity.oppsCreatedQuarter
  };
}

function sumCommonUserActivity(acc, user) {
  return {
    contactsCreatedWeek: acc.contactsCreatedWeek + user.contactsCreatedWeek,
    contactsCreatedMonth: acc.contactsCreatedMonth + user.contactsCreatedMonth,
    contactsCreatedQuarter: acc.contactsCreatedQuarter + user.contactsCreatedQuarter,
    ajCreatedWeek: acc.ajCreatedWeek + user.ajCreatedMonth,
    ajCreatedMonth: acc.ajCreatedMonth + user.ajCreatedMonth,
    ajCreatedQuarter: acc.ajCreatedQuarter + user.ajCreatedQuarter,
    oppsCreatedWeek: acc.oppsCreatedWeek + user.oppsCreatedWeek,
    oppsCreatedMonth: acc.oppsCreatedMonth + user.oppsCreatedMonth,
    oppsCreatedQuarter: acc.oppsCreatedQuarter + user.oppsCreatedQuarter
  };
}

const useChartStyles = makeStyles(() => ({
  root: {},
  chart: {
    height: 400
  }
}));
export {
  emptyUserActivityObj,
  userActivityLabels,
  commonUserActivityColumns,
  formatCommonUserActivity,
  sumCommonUserActivity,
  useChartStyles,
  commonUserActivityColumnsWithLabels
};
